import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class SalesHistory {
  constructor(data) {
    return {
      invoice_number: data.invoice_number || '',
      type: data.type || '',
      quote_number: data.quote_number || '',
      order_number: data.order_number || '',
      opportunity: data.opportunity || '',
      opportunity_id: data.opportunity_id || '',
      posting_date: data.posting_date ? moment(data.posting_date).format(DATE_FORMAT) : '',
      due_date: data.due_date ? moment(data.due_date).format(DATE_FORMAT) : '',
      sales_person: data.sales_person || '',
      total_amount: data.total_amount || '',
    }
  }
}
