<template>
  <b-tab title="Opportunities" no-body>
    <Opportunities :fields="opportunityFields" />
  </b-tab>
</template>

<script>
import Opportunities from '@/views/heroai/accounts/detail/components/accounts/Opportunity.vue'

export default {
  components: {
    Opportunities,
  },
  data() {
    return {
      opportunityFields: [
        {
          label: 'name',
          key: 'name',
          sortable: true,
          stickyColumn: true,
        },
        {
          label: 'stage name',
          key: 'stage_name',
          sortable: true,
        },
        {
          label: 'account management status',
          key: 'account_management_status',
          sortable: true,
        },
        {
          label: 'amount',
          key: 'amount',
          sortable: true,
        },
        {
          label: 'duration',
          key: 'duration',
          sortable: true,
          tdClass: 'text-right',
        },
        {
          label: 'Closed date',
          key: 'close_date',
          sortable: true,
        },
        {
          label: 'account manager',
          key: 'account_manager',
          sortable: true,
        },
        {
          label: 'legal entity',
          key: 'Legal_Entity__c',
          sortable: true,
        },
      ],
    }
  },
}
</script>
