import DataFormatService from '@/services/DataFormatService'
import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class SystemInfo {
  constructor(data) {
    return {
      created_date: DataFormatService.formatUsaDate(data.created_date) || '',
      last_modified_date: DataFormatService.formatUsaDate(data.last_modified_date) || '',
      // created_date: data.created_date ? moment(data.created_date).format(DATE_FORMAT) : '',
      // last_modified_date: data.last_modified_date ? moment(data.last_modified_date).format(DATE_FORMAT) : '',
    }
  }
}
