import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class SalesOrder {
  constructor(data) {
    return {
      invoice_number: data.invoice_number || '',
      document_type: data.document_type || '',
      opportunity: data.opportunity || '',
      order_date: data.order_date ? moment(data.order_date).format(DATE_FORMAT) : '',
      sales_person: data.sales_person || '',
      total_amount: data.total_amount || '',
      status: data.status || '',
    }
  }
}
