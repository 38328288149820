<template>
  <!-- Account Information -->
  <app-collapse-item title="Account Information" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="account-name"
          v-model="account.account_name"
          label="Account Name"
        />
      </b-col>
      <!-- <b-col cols="12" md="3">
        <HeroDisplayText
          id="account-code"
          v-model="account.account_code"
          label="Account Code"
        />
      </b-col> -->
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="client-legal-name"
          v-model="account.client_legal_name"
          label="Client Legal Name"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="account-owner"
          v-model="account.account_owner"
          label="Account Owner"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="tax-id"
          v-model="account.tax_id"
          label="Tax ID"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="tax-id"
          v-model="account.parent_account"
          label="Parent Account"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="legal-entity"
          v-model="account.legal_entity"
          label="Legal Entity"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="industry"
          v-model="account.industry"
          label="Industry"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="website"
          v-model="account.website"
          label="Website"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="vertical"
          v-model="account.vertical"
          label="Vertical"
        />
      </b-col>
      <!-- <b-col cols="12" md="3">
        <HeroDisplayText
          id="phone"
          v-model="account.phone"
          label="Phone"
        />
      </b-col> -->
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="fax"
          v-model="account.fax"
          label="Fax"
        />
      </b-col>
      <!-- <b-col cols="12" md="3">
        <HeroDisplayText
          id="contact-email"
          v-model="account.contact_email"
          label="Contact Email"
        />
      </b-col> -->
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="account-number"
          v-model="account.account_number"
          label="Account Number"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="account-currency"
          v-model="account.account_currency"
          label="Account Currency"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="count-opportunity"
          v-model="account.count_opportunity"
          label="Count Opportunity"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="bus-relation-contact-no"
          v-model="account.bus_relation_contact_no"
          label="Bus Relation Contact No"
        />
      </b-col>
      <!-- <b-col cols="12" md="3">
        <HeroDisplayLink
          id="salesforce-link-url"
          v-model="account.salesforce_link_url"
          label="Salesforce Link"
          custom-class="btn btn-secondary col-12 col-md-6"
          button-icon="GlobeIcon"
          button-label="Open"
        />
      </b-col> -->
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import BreadCrumbMixin from '@/mixins/breadcrumb'

export default {
  components: {
    AppCollapseItem,
    HeroDisplayText,
  },
  mixins: [BreadCrumbMixin],
  props: {
    account: {
      type: Object,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.setBreadCrumb(this.account.account_name)
  },
}
</script>
