<template>
  <!-- Custom Links -->
  <app-collapse-item title="Custom Links" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayLink
          id="general-term-service-eng"
          v-model="customLink.general_term_service_link_eng"
          :href="customLink.general_term_service_link_eng"
          label="General Term of Service-English[HLTH](New)"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayLink
          id="general-term-service-thai"
          v-model="customLink.general_term_service_link_thai"
          :href="customLink.general_term_service_link_thai"
          label="General Term of Service-Thai[HLTH](New)"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayLink from '@/views/components/form/displays/HeroDisplayLink.vue'
import CustomLnk from '@/models/AccountDetails/CustomLink'

export default {
  components: {
    AppCollapseItem,
    HeroDisplayLink,
  },
  props: {
    customLink: {
      type: Object,
      default: () => new CustomLnk({}),
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
