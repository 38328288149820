<template>
  <!-- System Information -->
  <app-collapse-item title="System Information" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="created-by"
          v-model="systemInfo.created_date"
          label="Created By"
        />
      </b-col>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="last-modified"
          v-model="systemInfo.last_modified_date"
          label="Last Modified By"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import SystemInfo from '@/models/AccountDetails/SystemInformation'

export default {
  components: {
    AppCollapseItem,
    HeroDisplayText,
  },
  props: {
    systemInfo: {
      type: Object,
      default: () => new SystemInfo({}),
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
