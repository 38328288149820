<template>
  <b-tab title="Details">
    <HeroButtonAction
      type="button"
      variant="primary"
      class="m-1 w-auto ml-auto"
      @click="collapseBtn"
    >
      {{ buttonText }}
    </HeroButtonAction>
    <b-overlay no-wrap :show="showOverlay" />
    <AccountInformation v-if="!isLoading" :account="account" :is-visible="isVisible || accountState.AccountInformation" @updatevisible="setAccordionState('AccountInformation')" />
    <AccountLevelValidations :account-validations="accountValidations" :is-visible="isVisible || accountState.AccountLevelValidations" @updatevisible="setAccordionState('AccountLevelValidations')" />
    <CustomLinks :custom-link="customLink" :is-visible="isVisible || accountState.CustomLinks" @updatevisible="setAccordionState('CustomLinks')" />
    <AddressInformation :address-info="addressInfo" :is-visible="isVisible || accountState.AddressInformation" @updatevisible="setAccordionState('AddressInformation')" />
    <SystemInformation :system-info="systemInfo" :is-visible="isVisible || accountState.SystemInformation" @updatevisible="setAccordionState('SystemInformation')" />
    <SalesOrders :fields="salesOrderFields" :is-visible="isVisible || accountState.SalesOrders" @updatevisible="setAccordionState('SalesOrders')" />
    <LedgerEntries :fields="ledgerEntriesFields" :is-visible="isVisible || accountState.LedgerEntries" @updatevisible="setAccordionState('LedgerEntries')" />
    <SalesHistory :fields="salesHistoryFields" :is-visible="isVisible || accountState.SalesHistory" @updatevisible="setAccordionState('SalesHistory')" />
    <AccountHistory :fields="accountHistoryFields" :is-visible="isVisible || accountState.AccountHistory" @updatevisible="setAccordionState('AccountHistory')" />
    <Invoices :fields="invoicesFields" :is-visible="isVisible || accountState.Invoices" @updatevisible="setAccordionState('Invoices')" />
  </b-tab>
</template>

<script>
import SalesOrders from '@/views/heroai/accounts/detail/components/accounts/SalesOrder.vue'
import LedgerEntries from '@/views/heroai/accounts/detail/components/accounts/LedgerEntry.vue'
import Account from '@/models/AccountDetails/Account'
import AccountLvlValidation from '@/models/AccountDetails/AccountLevelValidation'
import CustomLnk from '@/models/AccountDetails/CustomLink'
import AddressInfo from '@/models/AccountDetails/AddressInformation'
import SystemInfo from '@/models/AccountDetails/SystemInformation'
import SalesHistory from '@/views/heroai/accounts/detail/components/accounts/SalesHistory.vue'
import AccountHistory from '@/views/heroai/accounts/detail/components/accounts/AccountHistory.vue'
import Invoices from '@/views/heroai/accounts/detail/components/accounts/Invoice.vue'
import AccountInformation from '@/views/heroai/accounts/detail/components/accounts/AccountInformation.vue'
import AccountLevelValidations from '@/views/heroai/accounts/detail/components/accounts/AccountLevelValidation.vue'
import CustomLinks from '@/views/heroai/accounts/detail/components/accounts/CustomLink.vue'
import AddressInformation from '@/views/heroai/accounts/detail/components/accounts/AddressInformation.vue'
import SystemInformation from '@/views/heroai/accounts/detail/components/accounts/SystemInformation.vue'
import SweetAlert from '@/services/SweetAlert'
import AxiosService from '@/services/AxiosService'
import ErrorService from '@/services/ErrorService'
import Constant from '@/utils/Constant'
import Store from '@/store'
import AccountStateModal from '@/models/AccountDetails/AccountState'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'

export default {
  components: {
    HeroButtonAction,
    SalesOrders,
    LedgerEntries,
    SalesHistory,
    AccountHistory,
    Invoices,
    AccountInformation,
    AccountLevelValidations,
    CustomLinks,
    AddressInformation,
    SystemInformation,
  },
  data() {
    return {
      accountState: new AccountStateModal({}),
      isVisible: false,
      note: '',
      salesOrderFields: [
        { key: 'invoice_number', label: 'Number', sortable: true },
        { key: 'document_type', label: 'Document Type', sortable: true },
        { key: 'opportunity', label: 'Opportunity', sortable: true },
        { key: 'order_date', label: 'Order Date', sortable: true },
        { key: 'sales_person', label: 'Salesperson Name', sortable: true },
        { key: 'total_amount', label: 'Total Amount Incl. VAT', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      ledgerEntriesFields: [
        { key: 'entry_number', label: 'Entry Number', sortable: true },
        { key: 'posting_date', label: 'Posting Date', sortable: true },
        { key: 'document_type', label: 'Document Type', sortable: true },
        { key: 'document_no', label: 'Document No.', sortable: true },
        { key: 'amount', label: 'Amount', sortable: true },
        { key: 'remaining_amount', label: 'Remaining Amount', sortable: true },
        { key: 'due_date', label: 'Due Date', sortable: true },
        { key: 'invoice', label: 'Invoice/Credit Memo', sortable: true },
        { key: 'description', label: 'Description', sortable: true },
      ],
      salesHistoryFields: [
        { key: 'invoice_number', label: 'Invoice No.', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'quote_number', label: 'Quote No.', sortable: true },
        { key: 'order_number', label: 'Order No.', sortable: true },
        { key: 'opportunity', label: 'Opportunity', sortable: true },
        { key: 'posting_date', label: 'Posting Date', sortable: true },
        { key: 'due_date', label: 'Due Date', sortable: true },
        { key: 'sales_person', label: 'Salesperson Name', sortable: true },
        { key: 'total_amount', label: 'Total Amount Incl. VAT', sortable: true },
      ],
      accountHistoryFields: [
        { key: 'CreatedDate', label: 'Date', sortable: true },
        { key: 'account_field', label: 'Field', sortable: true },
        { key: 'user', label: 'User', sortable: true },
        { key: 'original_value', label: 'Original Value', sortable: true },
        { key: 'new_value', label: 'New Value', sortable: true },
      ],
      invoicesFields: [
        { key: 'invoice_number', label: 'Invoice No', sortable: true },
      ],
      showOverlay: true,
      collapseType: 'border',
      account: new Account({}),
      accountValidations: new AccountLvlValidation({}),
      customLink: new CustomLnk({}),
      addressInfo: new AddressInfo({}),
      systemInfo: new SystemInfo({}),
      isLoading: true,
    }
  },
  computed: {
    buttonText() {
      return this.checkExpandState()
    },
  },
  watch: {
    isLoading: {
      handler() {
        if (this.isLoading) {
          this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are loading account detail.' })
        } else {
          this.$swal().close()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.showOverlay = false
    this.isLoading = false
    this.getAccountDetail()
    this.accountState = Store.getters['heroAiCampaign/getAccountState']
    if (!this.accountState) {
      this.accountState = new AccountStateModal({})
      Store.commit('heroAiCampaign/setAccountState', new AccountStateModal({}))
    }
    this.checkAccordionState()
  },
  methods: {
    async getAccountDetail() {
      this.isLoading = true
      try {
        const qparam = {
          account_id: this.$route.params.id,
        }
        const response = await AxiosService.get(Constant.apiURL.salesforceAccountDetails, qparam)
        this.account = new Account(response.data.account_information)
        this.addressInfo = new AddressInfo(response.data.address_information)
        this.systemInfo = new SystemInfo(response.data.system_information)
        this.customLink = new CustomLnk(response.data.custom_links)
        this.accountValidations = new AccountLvlValidation(response.data.account_level_validations)
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      } finally {
        this.isLoading = false
      }
    },
    collapseBtn() {
      let ExpandAll = false
      if (this.buttonText === 'Expand All') {
        ExpandAll = true
      }
      const AccountState = this.accountState
      Object.keys(this.accountState).forEach(x => {
        AccountState[x] = ExpandAll
      })
      this.accountState = AccountState
      Store.commit('heroAiCampaign/setAccountState', this.accountState)
      this.isVisible = ExpandAll
    },
    checkExpandState() {
      let AllCollapsed = true
      Object.keys(this.accountState).forEach(x => {
        if (this.accountState[x]) {
          AllCollapsed = false
        }
      })
      return AllCollapsed ? 'Expand All' : 'Collapse All'
    },
    checkAccordionState() {
      let AllCollapsed = true
      Object.keys(this.accountState).forEach(x => {
        if (this.accountState[x]) {
          AllCollapsed = false
        }
      })
      if (AllCollapsed && this.isVisible) {
        this.isVisible = false
      }
    },
    setAccordionState(propertyName) {
      const PreviousState = this.accountState[propertyName]
      this.accountState[propertyName] = !PreviousState
      Store.commit('heroAiCampaign/setAccountState', this.accountState)
      this.checkAccordionState()
    },
  },
}
</script>
