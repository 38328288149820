import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class Account {
  constructor(data = {}) {
    return {
      id: data.id || '',
      account_id: data.account_id || '',
      account_name: data.account_name || '',
      client_legal_name: data.client_legal_name || '',
      account_code: data.account_code || '',
      account_number: data.account_number || '',
      industry: data.industry || '',
      sub_industry: data.sub_industry || '',
      country: data.country || '',
      salesforce_link_url: data.salesforce_link_url || '',
      account_owner: data.account_owner || '',
      tax_id: data.tax_id || '',
      parent_account: data.parent_account || '',
      legal_entity: data.legal_entity || '',
      website: data.website || '',
      vertical: data.vertical || '',
      phone: data.phone || '',
      fax: data.fax || '',
      contact_email: data.contact_email || '',
      account_currency: data.account_currency || '',
      count_opportunity: data.count_opportunity || '',
      bus_relation_contact_no: data.bus_relation_contact_no || '',
      billing_street: data.billing_street || '',
      sf_last_modified_date: data.LastModifiedDate || '',
      name: data.name || '',
      custom_name: data.custom_name || '',
    }
  }
}
