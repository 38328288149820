export default class AccountHistory {
  constructor(data) {
    return {
      CreatedDate: data.CreatedDate || '',
      account_field: data.account_field || '',
      user: data.user || '',
      original_value: data.original_value || '',
      new_value: data.new_value || '',
    }
  }
}
