<template>
  <!-- Address Information -->
  <app-collapse-item title="Address Information" :is-visible="isVisible" @visible="$emit('updatevisible')">
    <b-row>
      <b-col cols="12" md="3">
        <HeroDisplayText
          id="billing-address"
          v-model="addressInfo.billing_address"
          label="Billing Address"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import AddressInfo from '@/models/AccountDetails/AddressInformation'

export default {
  components: {
    AppCollapseItem,
    HeroDisplayText,
  },
  props: {
    addressInfo: {
      type: Object,
      default: () => new AddressInfo({}),
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
