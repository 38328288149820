<template>
  <b-card no-body>
    <b-tabs card pills>
      <app-collapse accordion :type="collapseType">
        <b-tabs align="left" style="margin-top: -50px">
          <DetailsTab />
          <OpportunitiesTab v-if="$can('Access', 'Opportunities')" />
        </b-tabs>
      </app-collapse>
    </b-tabs>
  </b-card>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import { BCard, BTabs } from 'bootstrap-vue'
import DetailsTab from '@/views/heroai/accounts/detail/components/DetailsTab.vue'
import OpportunitiesTab from '@/views/heroai/accounts/detail/components/OpportunitiesTab.vue'

export default {
  components: {
    BCard,
    BTabs,
    DetailsTab,
    OpportunitiesTab,
    AppCollapse,
  },
  data() {
    return {
      collapseType: 'border',
    }
  },
}
</script>
