import Invoice from '@/models/AccountDetails/Invoice'

export default class InvoicesFactory {
  static createFromJson(json) {
    return new Invoice(json)
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(InvoicesFactory.createFromJson(item))
    })

    return jsonData
  }
}
