import LedgerEntry from '@/models/AccountDetails/LedgerEntry'

export default class LedgerEntriesFactory {
  static createFromJson(json) {
    return new LedgerEntry(json)
  }

  static createFromJsonArray(jsonArray) {
    const jsonData = []
    jsonArray.forEach(item => {
      jsonData.push(LedgerEntriesFactory.createFromJson(item))
    })

    return jsonData
  }
}
