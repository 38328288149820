import { DATE_FORMAT } from '@/utils/Format'
import moment from 'moment'

export default class LedgerEntry {
  constructor(item = {}) {
    return {
      entry_number: item.entry_number || '',
      posting_date: item.posting_date ? moment(item.posting_date).format(DATE_FORMAT) : '',
      document_type: item.document_type || '',
      document_no: item.document_no || '',
      amount: item.amount || '',
      remaining_amount: item.remaining_amount || '',
      due_date: item.due_date ? moment(item.due_date).format(DATE_FORMAT) : '',
      invoice: item.invoice || '',
      open: item.open || '',
      description: item.description || '',
    }
  }
}
