export default class AccountLvlValidation {
  constructor(data) {
    return {
      risk_assessment_completed_by_finance: data.risk_assessment_completed_by_finance ? data.risk_assessment_completed_by_finance : false,
      general_term_of_service_signed: data.general_term_of_service_signed ? data.general_term_of_service_signed : false,
      finance_suggested_payment_terms: data.finance_suggested_payment_terms || '',
      term_url: data.term_url || '',
      PND_documents_approved_by_finance: data.PND_documents_approved_by_finance ? data.PND_documents_approved_by_finance : false,
      general_term: data.general_term || '',
    }
  }
}
